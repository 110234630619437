var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{class:_vm.$style.new_header,style:(_vm.main_style_padding),attrs:{"color":"#fff","height":"72px","outlined":"","flat":"","fixed":""}},[_c('div',{class:_vm.$style.barWrap},[_c('div',{class:_vm.$style.sidebarBtnWrap},[(_vm.is_not_desktop)?_c('v-btn',{class:_vm.$style.sidebarBtn,attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggle_sidebar(!_vm.is_mini_sidebar)}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_not_desktop ? true : _vm.is_mini_sidebar),expression:"is_not_desktop ? true : is_mini_sidebar"}]},[_vm._v(" mdi-chevron-right ")]),(!_vm.is_not_desktop)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_mini_sidebar),expression:"!is_mini_sidebar"}]},[_vm._v(" mdi-chevron-left ")]):_vm._e()],1):_vm._e()],1),_c('div',{class:_vm.$style.btnsWrap},[_c('div',{staticClass:"d-flex align-center ml-6"}),_c('div',{staticClass:"d-flex align-center"},[(_vm.polls.length)?_c('v-btn',{class:_vm.$style.pollsBtn,attrs:{"color":"accent darken-4","depressed":""},on:{"click":function($event){return _vm.toggle_polls_sidebar(!_vm.is_polls_sidebar)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-message-text-fast-outline")]),_vm._v(" Пройти опрос ")],1):_vm._e(),(_vm.is_not_accessible_role())?_c('v-btn',{class:_vm.$style.notifications,attrs:{"icon":""},on:{"click":_vm.open_cart}},[_c('span',[_c('v-icon',[_vm._v("mdi-cart-outline")]),(_vm.prods.length)?_c('Badge',{attrs:{"color":'accent darken-4',"content":_vm.prods.length,"offset-y":"-1","offset-x":"10"}}):_vm._e()],1)]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.$style.notifications,attrs:{"icon":""},on:{"click":function($event){return _vm.togle_notification_bar(!_vm.is_notification_bar)}}},'v-btn',attrs,false),on),[_c('span',[_c('v-icon',[_vm._v("mdi-bell-outline")]),(_vm.number_unread_notifications !== 0)?_c('Badge',{attrs:{"content":_vm.number_unread_notifications < 100 ? _vm.number_unread_notifications : '99+',"offset-y":"-1","offset-x":"10"}}):_vm._e()],1)])]}}])},[_c('span',[_vm._v(" Уведомления ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('WidgetsMenu')],1)]}}])},[_c('span',[_vm._v("Сервисы АТОЛ")])]),_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"sm","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[(_vm.avatar)?_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{class:_vm.$style.img_avatar,attrs:{"src":_vm.avatar,"alt":"avatar"}})]):_c('v-avatar',{attrs:{"size":"36"}},[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1)],1)]}}])},[_c('v-list',{class:_vm.$style.menuBox},[_c('ul',[_c('li',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center",class:_vm.$style.menu_list_item_user,on:{"click":_vm.go_to_my_profile}},[_c('div',{staticClass:"mx-3"},[_c('v-icon',{attrs:{"size":"28","text":""}},[_vm._v(" mdi-account-circle-outline ")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-medium display-1 mt-2",class:_vm.$style.menu_list_item_text},[_vm._v(_vm._s(_vm.me.name)+" "+_vm._s(_vm.me.patronymic))]),_c('span',{staticClass:"font-weight-medium display-1 mb-2",class:_vm.$style.menu_list_item_text},[_vm._v(_vm._s(_vm.me.surname))])])]),_c('v-divider',{staticClass:"my-2"})],1),_c('li',{staticClass:"d-flex",class:_vm.$style.menu_list_exitBtn,on:{"click":_vm.go_to_my_company}},[_c('div',{staticClass:"mx-3"},[_c('v-icon',{attrs:{"size":"28","text":""}},[_vm._v(" mdi-domain ")])],1),_c('div',[_c('span',{staticClass:"my-2",class:_vm.$style.menu_list_item_text},[_vm._v(_vm._s(_vm.me.company))])])]),_c('v-divider',{staticClass:"my-2"}),_c('li',{staticClass:"d-flex align-center",class:_vm.$style.menu_list_exitBtn,on:{"click":_vm.logout}},[_c('div',{staticClass:"mx-3"},[_c('v-icon',{attrs:{"color":"error","size":"28","text":""}},[_vm._v(" mdi-logout ")])],1),_c('div',[_c('span',{class:_vm.$style.menu_list_item_text},[_vm._v("Выход")])])])],1)])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }