var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.is_not_desktop && !_vm.not_resizeble)?_c('v-data-table',_vm._g(_vm._b({directives:[{name:"resizable-columns",rawName:"v-resizable-columns",value:(_vm.option),expression:"option"}],key:_vm.table_key,ref:"data_table",class:[
      _vm.$style.table,
      ( _obj = {}, _obj[_vm.$style.sticky_cells] = !_vm.is_end_table, _obj )
    ],attrs:{"locale":"ru-RU","loading-text":"Загрузка","item-class":_vm.getRowClass},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2):_c('v-data-table',_vm._g(_vm._b({ref:"data_table",class:[
      _vm.$style.table,
      ( _obj$1 = {}, _obj$1[_vm.$style.sticky_cells] = !_vm.is_end_table, _obj$1 )
    ],attrs:{"locale":"ru-RU","loading-text":"Загрузка","item-class":_vm.getRowClass},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }