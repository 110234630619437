var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$style.card},[(_vm.showTitle)?_c('h3',{class:['text-h3', _vm.$style.title]},[_vm._t("table-title",function(){return [_vm._v(" Основные данные ")]})],2):_vm._e(),_c('Table',{class:[
      _vm.$style.table,
      ( _obj = {}, _obj[_vm.$style.singleCell] = _vm.single_cell_render, _obj )
    ],attrs:{"hide-default-footer":"","hide-default-header":"","disable-pagination":"","items":_vm.cols,"not_resizeble":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [(!_vm.is_mobile)?_c('tr',{class:[_vm.is_even ? _vm.$style.tableRow_is_even : _vm.$style.tableRow_is_odd, _vm.$style.tableRow]},[_vm._t(("item-" + (item.field)),function(){return [(!_vm.single_cell_render)?[_vm._t(("text-item-" + (item.field)),function(){return [_c('td',{class:_vm.$style.tableCell},[_c('small',[_vm._v(" "+_vm._s(item.text)+" ")])])]},{"item":item}),_vm._t(("field-item-" + (item.field)),function(){return [_c('td',{class:_vm.$style.tableCell},[_vm._v(" "+_vm._s(_vm.items_info[item.field])+" ")])]},{"item":item})]:[_vm._t(("single-item-" + (item.field)),null,{"item":item})]]},{"props":item})],2):_c('div',{class:[_vm.$style.flexColumnMobile, 'mb-2']},[_c('v-divider',{staticClass:"mb-2"}),(!_vm.single_cell_render)?_c('div',{class:_vm.$style.cellMobileWrapper},[_vm._t(("text-item-" + (item.field)),function(){return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.text))])]},{"item":item}),_vm._t(("field-item-" + (item.field)),function(){return [_c('div',[_vm._v(_vm._s(_vm.items_info[item.field]))])]},{"item":item})],2):[_vm._t(("single-item-" + (item.field)),null,{"item":item})]],2)]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }