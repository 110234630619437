<script>
import { VStepperStep } from 'vuetify/lib/components/VStepper';

export default {
  name: 'stepper-step',
  extends: VStepperStep,
  data: () => ({
    current_icon: 'mdi-pencil-outline',
  }),
  methods: {
    genStepContent() {
      const children = [];

      if (this.hasError) {
        children.push(this.genIcon(this.errorIcon));
      } else if (this.complete) {
        if (this.editable) {
          children.push(this.genIcon(this.editIcon));
        } else {
          children.push(this.genIcon(this.completeIcon));
        }
      } else if (this.isActive) {
        children.push(this.genIcon(this.current_icon));
      } else {
        children.push(String(this.step));
      }

      return children;
    },
  },

  /**
   * рендер-функция скопирована ПОЛНОСТЬЮ из исходников vuetify/lib/components/VStepper
   * за исключением того, что добавляется класс this.$style.stepperStep
   * от которого стилизуется всё остальное
   */
  render(h) {
    return h('div', {
      attrs: {
        tabindex: this.editable ? 0 : -1,
      },
      staticClass: `${this.$style.stepperStep} v-stepper__step`,
      class: this.classes,
      directives: [
        {
          name: 'ripple',
          value: this.editable,
        },
      ],
      on: {
        click: this.click,
        keydown: this.keyboardClick,
      },
    }, [
      this.genStep(), this.genLabel(),
    ]);
  },

};
</script>
<style module lang="scss">
.stepperStep {
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 16px 16px 8px 16px;

  @media (min-width: 600px) and (max-width:960px) {
    .stepperStep {
      width: 100%;
    }
  }
  @media (max-width: 599px) {
    flex-direction: row !important;
    align-items: center !important;
    column-gap: 12px;
  }

  :global(.v-stepper__label) {
    text-shadow: none !important;
  }

  :global(.v-stepper__step__step) {
    width: 27px;
    height: 27px;
  }

  &:global(.v-stepper__step--complete) {
    :global(.v-stepper__step__step) {
      background-color: transparent !important;

      :global(.v-icon.v-icon) {
        color: #13E58A;
        font-size: 27px;
      }
    }

    :global(.v-stepper__label) {
      color: #13E58A;
      text-shadow: none !important;
    }
  }

  &:global(.v-stepper__step--active) {
    :global(.v-stepper__step__step) {
      background-color: transparent !important;
    }

    :global(.v-stepper__label) {
      color: $primary_darken1;
    }

    :global(.v-stepper__step__step .v-icon.v-icon) {
      color: $accent_darken4;
      font-size: 27px;
    }
  }

  &:global(.v-stepper__step--error) {
    :global(.v-stepper__step__step .v-icon.v-icon) {
      color: #ED1B2F;
      font-size: 27px;
    }

    :global(.v-stepper__label) {
      color: #ED1B2F;
      text-shadow: none !important;
    }
  }

  :global(.v-stepper__step__step) {
    margin-bottom: 12px;
    @media (max-width: 599px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 600px) and (max-width:960px) {
    :global(.v-stepper__label) {
      display: block !important;
    }
    .theme--light.v-stepper .v-stepper__header .v-divider {
      display: none;
    }
    .stepperStep {
      width: 100% !important;
    }
  }

  @media (max-width: 599px) {
    :global(.v-stepper__label) {
      display: block !important;
    }
  }
}
</style>
