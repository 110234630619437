<script>
import VStepper from 'vuetify/lib/components/VStepper/VStepper';

/**
 * Обёртка поверх v-stepper.
 * допилена до состояния, чтобы использовать с кастомизированным StepperStep.
 * props, methods и data - взяты из vuetify/lib/components/VStepper/VStepper.js
 *
 * @see https://github.com/vuetifyjs/vuetify/issues/7049#issuecomment-856144098
 */

export default {
  name: 'Stepper',
  mixins: [VStepper],
  methods: {
    register(item) {
      // console.log(item.$options);

      if (
        item.$options.name === 'stepper-step'
        || item.$options.name === 'v-stepper-step'
      ) {
        this.steps.push(item);
      } else if (item.$options.name === 'v-stepper-content') {
        // eslint-disable-next-line no-param-reassign
        item.isVertical = this.vertical;
        this.content.push(item);
      }
    },

    unregister(item) {
      if (
        item.$options.name === 'stepper-step'
        || item.$options.name === 'v-stepper-step'
      ) {
        this.steps = this.steps.filter((i) => i !== item);
      } else if (item.$options.name === 'v-stepper-content') {
        // eslint-disable-next-line no-param-reassign
        item.isVertical = this.vertical;
        this.content = this.content.filter((i) => i !== item);
      }
    },
  },
};

</script>
