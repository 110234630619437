var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({on:{"input":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[(_vm.is_show_title)?_c('v-card-title',{class:_vm.$style.titleBox},[_c('h2',{class:[
          ( _obj = {}, _obj[_vm.$style.titleCentered] = _vm.center_title, _obj ),
          'text-h2', _vm.$style.title
        ]},[_vm._t("title")],2),_c('v-btn',{class:_vm.$style.btnClose,attrs:{"icon":"","small":"","color":"primary darken-3","absolute":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1):_vm._e(),(_vm.is_show_text)?_c('v-card-text',{class:[
        ( _obj$1 = {}, _obj$1[_vm.$style.textCentered] = _vm.center_text, _obj$1 ),
        _vm.text_block_classes,
        _vm.$style.text,
        'text-body-1 pl-4 pr-4 pb-0 mb-4'
      ]},[_vm._t("text")],2):_vm._e(),_c('v-card-actions',{class:[
        _vm.$style.modalButtons,
        _vm.$style[_vm.bts_align] ]},[_vm._t("actions",function(){return [_c('v-btn',{class:_vm.$style.closeSuccessDialog,attrs:{"color":"accent darken-4","depressed":""},on:{"click":_vm.close}},[_vm._t("button-text",function(){return [_vm._v(" Завершить ")]})],2)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }