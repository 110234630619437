var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"max-width":"564"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[_c('v-card-title',[_c('h2',{class:[
          ( _obj = {}, _obj[_vm.$style.titleCentered] = _vm.center_title, _obj ),
          'text-h2'
        ]},[_vm._t("title")],2)]),_c('v-card-text',{class:[
        ( _obj$1 = {}, _obj$1[_vm.$style.textCentered] = _vm.center_text, _obj$1 ),
        'text-body-1'
      ]},[_vm._t("text")],2),_c('v-card-actions',{class:[_vm.$style.modalButtons, _vm.$style.center]},[_c('v-btn',{class:_vm.$style.closeSuccessDialog,attrs:{"depressed":"","color":"accent darken-4"},on:{"click":_vm.close}},[_vm._t("btn-text",function(){return [_vm._v(" Продолжить ")]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }